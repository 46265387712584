import React from "react"
import { graphql } from "gatsby"
import { getCurrentLangKey, getLangs, getUrlForLang } from "ptz-i18n"
import { IntlProvider } from "react-intl"
import "intl"
import Layout from "../components/Layout"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import ProjectsContent from "../components/english/projects/ProjectsContent"
import PageHeader from "../components/english/PageHeader"

const Projects = ({ data, location, i18nMessages }) => {
  const url = location.pathname
  const { langs, defaultLangKey } = data.site.siteMetadata.languages
  const langKey = getCurrentLangKey(langs, defaultLangKey, url)
  const homeLink = `/${langKey}/`
  const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url))
  return (
    <IntlProvider locale={langKey} messages={i18nMessages}>
      <Layout
        seoTitle="Projects"
        seoKeywords="JFEEI Projects, JFE Engineering Indonesia Projects, PT. JFE Engineering Indonesia Projects, JFEEI Services & Solution, JFE Engineering Indonesia Services/Solutions, PT. JFE Engineering Indonesia Services/Solutions, EPC Company, Industrial Plant, Lubricant, Petrochemical, General Manufacturing, Storage Facilities, Receiving Facilities, Pipeline, Geothermal, Waste Management, WWTP, WTP, WHR"
      >
        <Navbar langKey={langKey} langs={langsMenu} url={url} />
        <PageHeader
          pageTitle="Projects"
          pageDescription={data.markdownRemark.frontmatter.header?.description}
        />
        <ProjectsContent />
        <Footer langKey={langKey} />
      </Layout>
    </IntlProvider>
  )
}

export default Projects

export const query = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "projects" } }) {
      frontmatter {
        header {
          description
        }
      }
    }
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`
